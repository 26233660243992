<template>
  <c-box
    pos="relative"
    w="100%"
    h="100%"
    mx="auto"
    :bg="['#F2F2F2', '#FFF']"
    border="1px solid #f2f2f2"
    :box-shadow="['none', '2px 2px 10px rgba(0, 0, 0, 0.15)']"
    :border-radius="['0px', '16px']"
    :p="['1rem', '1.5rem']"
    :min-height="['calc(100vh - 62px)', '74vh']"
  >
    <Portal to="breadcrumb">
      <BreadcrumbPath
        px="90px"
        py="20px"
        :paths="[
          {
            label: 'Dashboard',
            href: '/',
          },
          {
            label: 'Keranjang',
            href: '/cart',
          },
          {
            label: 'Checkout',
            href: '/checkout',
            isCurrent: true,
          },
        ]"
      />
    </Portal>
    <c-box
      :w="['100%', '600px']"
      mx="auto"
      :padding-bottom="['50px', '0px']"
    >
      <c-text
        :d="['none', 'block']"
        font-family="Roboto"
        font-size="20px"
        color="#333"
        font-weight="700"
        mb="1rem"
        align="center"
      >
        Pembayaran
      </c-text>
      <c-flex
        flex-direction="row"
        justify-content="space-between"
        align-items="center"
        background="#FFFFFF"
        box-shadow="2px 2px 10px rgba(0, 0, 0, 0.15)"
        border-radius="12px"
        :padding="['16px', '24px']"
        mb="24px"
      >
        <c-flex
          :flex-direction="['column', 'row']"
          flex-grow="1"
          :align-items="['start', 'center']"
          width="100%"
        >
          <c-image
            :d="['none', 'block']"
            :src="require('@/assets/icons/icon-payment.svg')"
            w="40px"
            h="40px"
            mr="1rem"
          />
          <c-text
            font-family="Roboto"
            :font-size="['16px', '20px']"
            color="#333"
            :font-weight="['500', '700']"
          >
            Total Pembayaran
          </c-text>
          <c-text
            :d="['block', 'none']"
            font-family="Roboto"
            font-size="12px"
            color="#333"
            :font-weight="['400', '700']"
            mt="4px"
          >
            No. Pesanan #{{ transaction.invoiceNumber }}
          </c-text>
        </c-flex>
        <c-text
          flex-shrink="0"
          font-family="Roboto"
          :font-size="['20px', '28px']"
          color="#00A68C"
          font-weight="700"
        >
          {{ getTotal }}
        </c-text>
      </c-flex>
      <c-text
        font-family="Roboto"
        :font-size="['16px', '20px']"
        color="#333"
        :font-weight="['500', '700']"
        :mb="['1rem', '1rem']"
        :text-align="['left', 'center']"
      >
        Pilih Metode Pembayaran
      </c-text>
      <PaymentMethod
        v-model="selectedPayment"
        :detail-payment="detailPayment"
        :loading="loading"
        :padding-bottom="true"
        @handleChangePaymentMethod="handleChangePaymentMethod"
        @handleChangePaymentMethodId="handleChangePaymentMethodId"
      />

      <c-flex justify-content="center">
        <c-button
          :d="['none', 'block']"
          background="#008C81"
          color="#FFF"
          w="400px"
          font-family="Roboto"
          font-size="16px"
          font-weight="500"
          border-radius="50px"
          p="16px 50px"
          h="50px"
          mt="1rem"
          :disabled="isDisabledPayment"
          :is-loading="loading"
          loading-text="Memperbarui Pembayaran"
          @click="transaction.paymentMethodId != null ? onOpenConfirm() : submitPayment()"
        >
          {{ transaction.paymentMethodId ? 'Ubah Metode Pembayaran' : 'Lanjutkan Pembayaran' }}
        </c-button>
      </c-flex>
    </c-box>
    <c-flex
      :display="['block', 'none']"
      pos="fixed"
      bottom="0"
      left="0"
      right="0"
      justify-content="center"
      w="100%"
      background-color="#F2F2F2"
      padding-block="12px"
      padding-inline="16px"
    >
      <c-button
        display="block"
        background="#008C81"
        color="#FFF"
        w="100%"
        font-family="Roboto"
        font-size="14px"
        font-weight="500"
        border-radius="50px"
        p="10px 50px"
        h="50px"
        :disabled="isDisabledPayment"
        :is-loading="loading"
        loading-text="Memperbarui Pembayaran"
        @click="transaction.paymentMethodId != null ? onOpenConfirm() : submitPayment()"
      >
        {{ transaction.paymentMethodId ? 'Ubah Metode Pembayaran' : 'Lanjutkan Pembayaran' }}
      </c-button>
    </c-flex>
    
    <ModalConfirm 
      title="Apa kamu yakin ingin mengganti metode pembayaran?"
      :is-open="isConfirm"
      @close="onCloseConfirm"
      @submit="() => { 
        onCloseConfirm()
        submitPayment()
      }"
    />

    <ModalInfo
      for="confirmExitTransactionPage"
      :is-open="paymentPage.isOpenModalConfirmExitTransactionPage"
      :with-button-close="false"
      :close-on-overlay-click="false"
      :is-loading-exit-page="isLoadingExitPage"
      @close="setPaymentPage({
        isOpenModalConfirmExitTransactionPage: false,
        customChangeFn: '',
      })"
      @handle-exit-page="handleExitPage"
    />

    <BaseModal
      :is-open="isOpenPopupCouponError"
      :close-on-overlay-click="false"
      :with-button-close="false"
    >
      <template #header>
        <c-box
          display="flex"
          justify-content="center"
          margin="24px 0 0 0"
        >
          <c-image
            height="160px"
            object-fit="cover"
            :src="require('@/assets/images/illustration-090323-raw.jpeg')"
            alt="image cant use voucher"
          />
        </c-box>
      </template>
      <template #body>
        <c-flex
          flex-direction="column"
          justify-content="center"
          align-items="center"
          padding="30px 24px"
          gap="16px"
        >
          <BaseText
            size-mobile="18px"
            size-desktop="20px"
            color="primary.400"
            text-align="center"
          >
            Yah! Voucher kamu tidak <br>
            dapat digunakan
          </BaseText>

          <BaseText
            size-mobile="14px-2"
            size-desktop="16px"
            text-align="center"
          >
            Kamu terlalu lama di halaman ini, <br>
            silahkan masukkan ulang kode voucher
          </BaseText>
        </c-flex>
      </template>
      <template #footer>
        <c-box
          width="100%"
          padding-left="24px"
          padding-right="24px"
          margin-bottom="24px"
          display="flex"
          justify-content="center"
          padding="0 30%"
        >
          <BaseButton
            color="primary"
            rounded="1000px"
            width="100%"
            @click="handleClosePopupCouponError"
          >
            Kembali
          </BaseButton>
        </c-box>
      </template>
    </BaseModal>
  </c-box>
</template>

<script>
import _ from 'lodash'
import BreadcrumbPath from '@/components/elements/breadcrumb-path.vue'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import { formatCurrency } from '@/utils/format-currency'
import PaymentMethod from '@/components/payment-method.vue'
import ModalConfirm from '@/components/widgets/modal-confirm'
import { isExpiryDate } from '@/utils/is-expiry-date'
import dayjs from 'dayjs'
import ModalInfo from '@/components/widgets/modal-info/index.vue'
import { reqResetCoupon } from '@/requests/dietela-api/req-reset-coupon'
import BaseText from '@/components/elements/base-text.vue'
import BaseModal from '@/components/elements/base-modal.vue'
import BaseButton from '@/components/elements/base-button.vue'
import { parseErrorCatch } from '@/utils/parse-error-catch'
import { flags } from '@/utils/flags'

export default {
  name: 'SelectPayment',
  components: {
    BaseButton, BaseModal, BaseText,
    ModalInfo,
    BreadcrumbPath,
    PaymentMethod,
    ModalConfirm,
  },
  beforeRouteLeave(to, from, next) {
    if (this.transactionDetail.status !== 'pending') {
      next()
      return
    }

    if (this.paymentPage.isRouteGuardActive) {
      this.routeLeaveToPath = to.path || ''
      this.setPaymentPage({
        isOpenModalConfirmExitTransactionPage: true,
      })
    }

    if (window.popStateDetected) {
      history.go(1)
    }
    if (!this.paymentPage.isRouteGuardActive) {
      next()
      return
    }
  },
  data() {
    return {
      isOpenPopupCouponError: false,
      isLoadingExitPage: false,
      transactionId: this.$route.params.id,
      selectedPayment: '',
      detailPayment: {
        isCreditCard: null,
        additionalInfo: {
          cardNumber: '',
          cardCVV: '',
          expMonth: '',
          expYear: '',
        },
      },
      loading: false, // loading for submitting payment
      isConfirm: false,
      transactionDetail: {},
      routeLeaveToPath: '',
    }
  },
  computed: {
    ...mapGetters({
      transaction: 'checkout/transaction',
      paymentPage: 'general/paymentPage',
      getCouponId: 'generalPersist/getCouponId',
    }),
    getTotal() {
      return this.transaction.total ? formatCurrency(this.transaction.total) : '-'
    },
    isCreditCardValid() {
      const { cardNumber, cardCVV, expMonth, expYear } = this.detailPayment.additionalInfo
      if (cardNumber && cardCVV && expMonth && expYear) {
        return true
      }
      return false
    },
    isUsingCreditCard() {
      return this.detailPayment.isCreditCard === 1
    },
    isDisabledPayment() {
      if (this.isUsingCreditCard) return !this.isCreditCardValid
      if (this.selectedPayment && !_.isEmpty(this.transaction) && this.selectedPayment != this.transaction.paymentMethodId) {
        return false
      }
      return true
    },
  },
  watch: {
    transaction: {
      immediate: true,
      handler(val) {
        if (val && val.id == this.transactionId && val.paymentMethodId) {
          this.selectedPayment = val.paymentMethodId
        }
      },
    },
  },
  mounted() {
    // detect browser back button
    window.popStateDetected = false
    window.addEventListener('popstate', () => {
      window.popStateDetected = true
    })
  },
  async created() {
    // reset store
    this.setPaymentPage({
      isRouteGuardActive: true,
      customChangeFn: '',
    })
    await this.fetchTransactionDetail()
  },
  methods: {
    ...mapMutations({
      setPaymentPage: 'general/setPaymentPage',
      resetCouponId: 'generalPersist/resetCouponId',
    }),
    ...mapActions({
      getTransaction: 'checkout/getTransaction',
      createMidtransCharge: 'checkout/createMidtransCharge',
      updateMidtransCharge: 'checkout/updateMidtransCharge',
      onUpdateCart: 'cart/onUpdateCart',
      fetchListCart: 'cart/list',
      signout: 'auth/signout',
    }),
    async fetchTransactionDetail() {
      try {
        if (_.isEmpty(this.transaction) || this.transaction.id !== this.transactionId) {
          const res = await this.getTransaction(this.transactionId)
          if (_.isEmpty(res.data)) {
            this.$router.push('/')
          }
          if (res.data.status !== 'pending') {
            this.$router.push({
              name: 'client.profile.historyTransaction',
            })
          }

          this.transactionDetail = res.data

          const subtractedDate = dayjs(res.data.expiryAt).subtract(7, 'h')
          const localDate = new Date(subtractedDate)
          const isPaymentExpired = isExpiryDate(localDate)
          if (isPaymentExpired) {
            this.$toast({
              status: 'warning',
              title: 'Ooops...',
              description: 'Waktu pembayaran habis. Silakan melakukan pembelian ulang.',
              duration: 4000,
            })
            this.$router.push({
              name: 'client.profile.historyTransaction',
            })
            return
          }
          const isPaymentCreditCard = res?.data?.redirectUrl
          if (isPaymentCreditCard) {
            this.$router.push({
              name: 'client.checkout.redirect-midtrans-3ds',
              query: {
                transactionId: res?.data?.transactionId,
                redirectUrl: res?.data?.redirectUrl,
              },
            })
            return
          }
        }
      } catch (err) {
        this.$toast({
          status: 'error',
          title: 'Error',
          description: err?.message,
          duration: 3000,
        })
        this.$router.push('/')
      }
    },
    handleClosePopupCouponError() {
      this.resetCouponId()
      this.isOpenPopupCouponError = false
    },
    handleChangePaymentMethod(detailPayment) {
      this.detailPayment = detailPayment
    },
    handleChangePaymentMethodId(paymentMethodId) {
      this.selectedPayment = paymentMethodId
    },
    submitPayment() {
      this.loading = true
      const isCreditCartPayment = this.detailPayment.isCreditCard === 1
      const isUpdatePayment = !!this.transaction.paymentMethodId
      if (isCreditCartPayment) {
        const payload = {
          transactionId: this.transactionId,
          paymentMethodId: this.selectedPayment,
          couponId: this.getCouponId,
          ...this.detailPayment.additionalInfo,
        }
        if (isUpdatePayment) {
          this.updateMidtransCharge(payload)
            .then((res) => {
              this.setPaymentPage({
                isRouteGuardActive: false,
              })
              this.resetCouponId()
              if (res.redirectUrl) {
                this.$router.push({
                  name: 'client.checkout.redirect-midtrans-3ds',
                  query: {
                    transactionId: res?.transactionId,
                    redirectUrl: res.redirectUrl,
                  },
                })
              } })
            .catch((err) => {
              this.$toast({
                status: 'error',
                title: `Error: ${err.status}`,
                description: parseErrorCatch(err),
                duration: 5000,
              })
            })
            .finally(() => {
              this.loading = false
            })
        } else {
          this.createMidtransCharge(payload)
            .then((res) => {
              this.setPaymentPage({
                isRouteGuardActive: false,
              })
              this.resetCouponId()
              if (res.redirectUrl) {
                this.$router.push({
                  name: 'client.checkout.redirect-midtrans-3ds',
                  query: {
                    transactionId: res?.transactionId,
                    redirectUrl: res.redirectUrl,
                  },
                })
              }
            })
            .catch(async(err) => {
              if (err.data?.message?.toUpperCase() === 'COUPON NOT MATCH') {
                this.isOpenPopupCouponError = true
                const res = await this.getTransaction(this.transactionId)
                this.transactionDetail = res.data
                return
              }
              this.$toast({
                status: 'error',
                title: `Error: ${err.status}`,
                description: parseErrorCatch(err),
                duration: 5000,
              })
            })
            .finally(() => {
              this.loading = false
            })
        }

        return
      }

      // API for bank transfer and gopay
      const payload = {
        transactionId: this.transactionId,
        paymentMethodId: this.selectedPayment,
        couponId: this.getCouponId,
      }
      if (this.transaction.paymentMethodId) {
        this.updateMidtransCharge(payload)
          .then(() => {
            this.setPaymentPage({
              isRouteGuardActive: false,
            })
            this.resetCouponId()
            this.$router.push(`/checkout/${this.transactionId}/detail`)
          })
          .catch((err) => {
            this.$toast({
              status: 'error',
              title: `Error: ${err.status}`,
              description: parseErrorCatch(err),
              duration: 5000,
            })
          })
          .finally(() => {
            this.loading = false
          })
      } else {
        this.createMidtransCharge(payload)
          .then(() => {
            this.setPaymentPage({
              isRouteGuardActive: false,
            })
            this.resetCouponId()
            this.$router.push(`/checkout/${this.transactionId}/detail`)
          })
          .catch(async(err) => {
            if (err.data?.message?.toUpperCase() === 'COUPON NOT MATCH') {
              this.isOpenPopupCouponError = true
              const res = await this.getTransaction(this.transactionId)
              this.transactionDetail = res.data
              return
            }
            this.$toast({
              status: 'error',
              title: `Error: ${err.status}`,
              description: parseErrorCatch(err),
              duration: 5000,
            })
          })
          .finally(() => {
            this.loading = false
          })
      }
    },
    onOpenConfirm() {
      this.isConfirm = true
    },
    onCloseConfirm() {
      this.isConfirm = false
    },
    async handleExitPage() {
      try {
        this.resetCouponId()
        this.setPaymentPage({
          isRouteGuardActive: false,
        })
        this.isLoadingExitPage = true
        await this.onUpdateCart({
          productServiceId: this.transactionDetail?.productServiceId,
          quantity: this.transactionDetail?.quantity,
        })
        await this.fetchListCart()
        await reqResetCoupon(
          this.$store.getters.axios,
          {
            transactionId: this.transactionId,
          })
        
        if (this.paymentPage.customChangeFn === 'LOGOUT') {
          this.signout()
            .then(() => {
              this.$store.dispatch('footer/getFooter')
              this.$store.commit('resetStore')
              flags.redirectAfterLogout(this.$route, this.$router)
            })
            .catch(() => {
              flags.redirectAfterLogout(this.$route, this.$router)
            })
          return
        }
        this.$router.replace(this.routeLeaveToPath)
      } catch (e) {
        console.error('handleExitPage', e)
      } finally {
        this.setPaymentPage({
          isOpenModalConfirmExitTransactionPage: false,
        })
        this.isLoadingExitPage = false
      }
    },
  },
}
</script>
